import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="w-full px-5 py-3 md:px-6 lg:px-8 bg-blue-900">
      <div className="flex content-between justify-between w-full max-w-screen-xl mx-auto text-center">
        <p className="w-full text-base text-gray-300">© {currentYear} <a href="https://modular-web.com" className="hover:underline" target="blank">Modular</a>. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
