import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as SwissFlag } from "../icons/swiss-flag.svg";
import MovingForm from "./MovingForm";

const HeroSection = () => {
  return (
    <section className="relative bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 text-white overflow-hidden">
      {/* SEO Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          name: "SNS Transport",
          "@id": "https://sns-transport.ch",
          image: ["https://sns-transport.ch/logo512.png"],
          description:
            "Entreprise de déménagement professionnelle basée à Moudon, offrant des services de déménagement dans les cantons de Vaud, Fribourg et toute la Suisse.",
          address: {
            "@type": "PostalAddress",
            streetAddress: "Moudon",
            addressRegion: "Vaud",
            addressCountry: "CH",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 46.6693,
            longitude: 6.7977,
          },
          url: "https://sns-transport.ch",
          telephone: "+41762227571",
          areaServed: ["Canton de Vaud", "Canton de Fribourg", "Suisse"],
          priceRange: "$$",
          openingHoursSpecification: {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ],
            opens: "07:00",
            closes: "17:00",
          },
        })}
      </script>

      {/* Animated background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-t from-blue-900/50 to-transparent"></div>
        <div className="absolute inset-0">
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{
                opacity: [0.1, 0.3, 0.1],
                scale: [1, 1.2, 1],
                x: [0, 50, 0],
                y: [0, 30, 0],
              }}
              transition={{
                duration: 10 + i * 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="absolute rounded-full bg-gradient-to-br from-blue-400/20 to-transparent"
              style={{
                width: `${300 + i * 100}px`,
                height: `${300 + i * 100}px`,
                left: `${20 + i * 30}%`,
                top: `${20 + i * 20}%`,
                filter: "blur(40px)",
              }}
            />
          ))}
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto px-4 py-28">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Content */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="lg:pr-8"
          >
            <div className="flex items-center space-x-2 mb-4">
              <SwissFlag className="w-6 h-6" />
              <p className="font-medium text-blue-100">
                Déménagement Vaud & Fribourg
              </p>
            </div>
            <h1 className="text-4xl lg:text-5xl font-bold mb-6">
              <span
                className="bg-gradient-to-r from-white via-blue-100 to-white bg-clip-text text-transparent"
                style={{ lineHeight: "1.2" }}
              >
                Votre déménagement professionnel à Moudon, Vaud et Fribourg
              </span>
            </h1>
            <p className="text-xl mb-8 text-blue-100 leading-normal">
              Entreprise de déménagement basée à Moudon, nous offrons des
              services professionnels dans les cantons de Vaud, Fribourg et
              toute la Suisse.
            </p>

            {/* Trust Indicators */}
            <div className="grid grid-cols-3 gap-4 mb-8">
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-4 text-center"
              >
                <div className="text-green-400 mb-2">
                  <svg
                    className="w-6 h-6 mx-auto"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="text-sm font-medium leading-snug text-blue-100">
                  Devis Gratuit
                </p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-4 text-center"
              >
                <div className="text-green-400 mb-2">
                  <svg
                    className="w-6 h-6 mx-auto"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="text-sm font-medium leading-snug text-blue-100">
                  Équipe Pro
                </p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-4 text-center"
              >
                <div className="text-green-400 mb-2">
                  <svg
                    className="w-6 h-6 mx-auto"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="text-sm font-medium text-blue-100 leading-snug">
                  Prix Compétitifs
                </p>
              </motion.div>
            </div>

            {/* Additional Trust Elements */}
            <div className="flex items-center gap-6 text-blue-100">
              <div className="flex items-center gap-2">
                <svg
                  className="w-5 h-5 text-green-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Service 7j/7</span>
              </div>
              <div className="flex items-center gap-2">
                <svg
                  className="w-5 h-5 text-green-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Réponse en 24h</span>
              </div>
            </div>
          </motion.div>

          {/* Right Column - Form */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="relative"
          >
            <MovingForm />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
