import React, { useState } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

// Form validation schema
const formSchema = z.object({
  name: z.string().min(2, "Le nom doit contenir au moins 2 caractères"),
  email: z.string().email("Email invalide"),
  phone: z.string().regex(/^(\+41|0)[1-9]\d{8}$/, "Numéro de téléphone suisse invalide"),
  fromAddress: z.string().min(5, "L'adresse doit contenir au moins 5 caractères"),
  fromFloor: z.string().optional(),
  fromElevator: z.boolean().default(false),
  toAddress: z.string().min(5, "L'adresse doit contenir au moins 5 caractères"),
  toFloor: z.string().optional(),
  toElevator: z.boolean().default(false),
  moveDate: z.string().refine((date) => {
    const selectedDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return selectedDate >= today;
  }, "La date doit être dans le futur"),
  propertyType: z.enum(["apartment", "house", "office", "storage"], {
    errorMap: () => ({ message: "Veuillez sélectionner un type de bien" }),
  }),
  rooms: z.enum(["1", "2", "3", "4", "5+"], {
    errorMap: () => ({ message: "Veuillez sélectionner le nombre de pièces" }),
  }),
  message: z.string().optional(),
});

const FormField = ({ label, name, type = "text", options, register, errors, ...props }) => {
  const error = errors[name];
  const inputClasses = `
    w-full
    px-4
    py-3
    text-gray-900
    placeholder-gray-400
    bg-white
    border
    rounded-xl
    outline-none
    transition-all
    duration-200
    ${error 
      ? "border-red-300 focus:border-red-500 focus:ring-2 focus:ring-red-200" 
      : "border-gray-200 hover:border-blue-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-100"
    }
    ${type === "select" ? "appearance-none" : ""}
  `;

  const ErrorMessage = ({ error }) => (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      className="mt-2 text-sm text-red-500 flex items-center gap-1.5"
    >
      <svg className="w-4 h-4 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <span className="flex-1 leading-tight">{error.message}</span>
    </motion.div>
  );

  if (type === "checkbox") {
    return (
      <div className="relative">
        <label className="flex items-center gap-2 text-sm text-gray-600 cursor-pointer group">
          <input
            type="checkbox"
            {...register(name)}
            className="
              w-5
              h-5
              border
              rounded
              text-blue-600
              border-gray-300
              focus:ring-0
              focus:ring-offset-0
              transition-all
              duration-200
              hover:border-blue-400
              checked:hover:border-blue-600
              cursor-pointer
            "
            {...props}
          />
          <span>{label}</span>
        </label>
        {error && <ErrorMessage error={error} />}
      </div>
    );
  }

  return (
    <div className="relative space-y-1">
      <label className="block text-sm font-medium text-gray-700">
        {label}
        {props.required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div>
        {type === "select" ? (
          <div className="relative">
            <select
              {...register(name)}
              className={inputClasses}
              {...props}
            >
              <option value="">{props.placeholder || "Sélectionnez"}</option>
              {options?.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
        ) : type === "textarea" ? (
          <textarea
            {...register(name)}
            className={inputClasses}
            {...props}
          />
        ) : (
          <input
            type={type}
            {...register(name)}
            className={inputClasses}
            {...props}
          />
        )}
        {error && <ErrorMessage error={error} />}
      </div>
    </div>
  );
};

const MovingForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    watch,
    trigger,
  } = useForm({
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  // Add validation for each step
  const isStepValid = async (step) => {
    let fieldsToValidate = [];
    switch (step) {
      case 1:
        fieldsToValidate = ['name', 'email', 'phone'];
        break;
      case 2:
        fieldsToValidate = ['fromAddress', 'toAddress'];
        break;
      case 3:
        fieldsToValidate = ['propertyType', 'rooms', 'moveDate'];
        break;
    }
    const result = await trigger(fieldsToValidate);
    return result;
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const formattedDate = format(new Date(data.moveDate), 'dd/MM/yyyy', { locale: fr });
      
      const formData = {
        name: data.name,
        email: data.email,
        message: `
Nouvelle demande de déménagement:

Informations personnelles:
- Nom: ${data.name}
- Email: ${data.email}
- Téléphone: ${data.phone}

Détails du déménagement:
- Adresse de départ: ${data.fromAddress}
- Étage de départ: ${data.fromFloor || 'Non spécifié'}
- Ascenseur disponible au départ: ${data.fromElevator ? 'Oui' : 'Non'}

- Adresse d'arrivée: ${data.toAddress}
- Étage d'arrivée: ${data.toFloor || 'Non spécifié'}
- Ascenseur disponible à l'arrivée: ${data.toElevator ? 'Oui' : 'Non'}

Informations complémentaires:
- Type de bien: ${data.propertyType}
- Nombre de pièces: ${data.rooms}
- Date souhaitée: ${formattedDate}
- Message: ${data.message || 'Aucun message'}`
      };

      const response = await fetch(
        `${process.env.REACT_APP_GOOGLE_SCRIPT_URL}`,
        {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      setSubmitStatus('success');
      reset();
      setTimeout(() => {
        setSubmitStatus(null);
        setCurrentStep(1); // Reset to first step
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
      setTimeout(() => setSubmitStatus(null), 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const nextStep = async () => {
    const isValid = await isStepValid(currentStep);
    if (isValid) {
      setCurrentStep((prev) => Math.min(prev + 1, totalSteps));
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  return (
    <div className="bg-white rounded-2xl shadow-2xl p-6 lg:p-8">
      {/* Progress Bar */}
      <div className="mb-8">
        <div className="flex justify-between mb-2">
          <span className="text-sm text-gray-600">Étape {currentStep} sur {totalSteps}</span>
          <span className="text-sm text-blue-600 font-medium">{Math.round((currentStep / totalSteps) * 100)}%</span>
        </div>
        <div className="h-2 bg-gray-100 rounded-full">
          <motion.div
            className="h-full bg-gradient-to-r from-blue-600 to-blue-700 rounded-full"
            initial={{ width: 0 }}
            animate={{ width: `${(currentStep / totalSteps) * 100}%` }}
            transition={{ duration: 0.3 }}
          />
        </div>
      </div>

      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        {currentStep === 1 && "Informations personnelles"}
        {currentStep === 2 && "Détails du déménagement"}
        {currentStep === 3 && "Informations complémentaires"}
      </h2>

      {submitStatus === "success" && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-6 p-4 bg-green-50 text-green-700 rounded-xl flex items-center gap-3"
        >
          <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          <span>Votre demande a été envoyée avec succès! Nous vous contacterons bientôt.</span>
        </motion.div>
      )}

      {submitStatus === "error" && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-6 p-4 bg-red-50 text-red-700 rounded-xl flex items-center gap-3"
        >
          <svg className="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <span>Une erreur est survenue. Veuillez réessayer plus tard.</span>
        </motion.div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        {/* Step 1: Personal Information */}
        {currentStep === 1 && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            <div className="grid md:grid-cols-2 gap-6">
              <FormField
                label="Nom complet"
                name="name"
                required
                placeholder="Jean Dupont"
                register={register}
                errors={errors}
              />
              <FormField
                label="Téléphone"
                name="phone"
                type="tel"
                required
                placeholder="+41123456789"
                register={register}
                errors={errors}
              />
            </div>
            <FormField
              label="Email"
              name="email"
              type="email"
              required
              placeholder="jean.dupont@example.com"
              register={register}
              errors={errors}
            />
          </motion.div>
        )}

        {/* Step 2: Moving Details */}
        {currentStep === 2 && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            <div className="space-y-6">
              <div className="p-4 bg-blue-50 rounded-xl">
                <h3 className="font-semibold text-blue-900 mb-4">Adresse de départ</h3>
                <div className="space-y-4">
                  <FormField
                    label="Adresse"
                    name="fromAddress"
                    required
                    placeholder="Rue, numéro, ville"
                    register={register}
                    errors={errors}
                  />
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <FormField
                        label="Étage"
                        name="fromFloor"
                        type="number"
                        placeholder="0"
                        register={register}
                        errors={errors}
                      />
                    </div>
                    <div className="flex items-end pb-2">
                      <FormField
                        type="checkbox"
                        name="fromElevator"
                        label="Ascenseur"
                        register={register}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 bg-blue-50 rounded-xl">
                <h3 className="font-semibold text-blue-900 mb-4">Adresse d'arrivée</h3>
                <div className="space-y-4">
                  <FormField
                    label="Adresse"
                    name="toAddress"
                    required
                    placeholder="Rue, numéro, ville"
                    register={register}
                    errors={errors}
                  />
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <FormField
                        label="Étage"
                        name="toFloor"
                        type="number"
                        placeholder="0"
                        register={register}
                        errors={errors}
                      />
                    </div>
                    <div className="flex items-end pb-2">
                      <FormField
                        type="checkbox"
                        name="toElevator"
                        label="Ascenseur"
                        register={register}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {/* Step 3: Additional Information */}
        {currentStep === 3 && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            <div className="grid md:grid-cols-3 gap-6">
              <FormField
                label="Type de bien"
                name="propertyType"
                type="select"
                required
                options={[
                  { value: "apartment", label: "Appartement" },
                  { value: "house", label: "Maison" },
                  { value: "office", label: "Bureau" },
                  { value: "storage", label: "Stockage" },
                ]}
                register={register}
                errors={errors}
              />
              <FormField
                label="Pièces"
                name="rooms"
                type="select"
                required
                options={[
                  { value: "1", label: "1 pièce" },
                  { value: "2", label: "2 pièces" },
                  { value: "3", label: "3 pièces" },
                  { value: "4", label: "4 pièces" },
                  { value: "5+", label: "5+ pièces" },
                ]}
                register={register}
                errors={errors}
              />
              <FormField
                label="Date souhaitée"
                name="moveDate"
                type="date"
                required
                min={new Date().toISOString().split('T')[0]}
                register={register}
                errors={errors}
              />
            </div>

            <div>
              <FormField
                label="Message (optionnel)"
                name="message"
                type="textarea"
                rows="4"
                placeholder="Détails particuliers, contraintes, etc."
                register={register}
                errors={errors}
              />
            </div>
          </motion.div>
        )}

        {/* Navigation Buttons */}
        <div className="flex items-center justify-between pt-4">
          {currentStep > 1 ? (
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="button"
              onClick={prevStep}
              className="px-6 py-3 text-blue-600 font-medium hover:text-blue-700 focus:outline-none flex items-center gap-2"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
              Retour
            </motion.button>
          ) : (
            <div></div>
          )}

          {currentStep < totalSteps ? (
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="button"
              onClick={nextStep}
              className={`px-8 py-3 bg-gradient-to-r font-medium rounded-xl shadow-lg flex items-center gap-2 ${
                Object.keys(errors).length > 0
                  ? "from-gray-400 to-gray-500 cursor-not-allowed"
                  : "from-blue-600 to-blue-700 text-white hover:from-blue-700 hover:to-blue-800"
              }`}
              disabled={Object.keys(errors).length > 0}
            >
              Suivant
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </motion.button>
          ) : (
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              disabled={isSubmitting || !isValid}
              className={`px-8 py-3 font-medium rounded-xl shadow-lg flex items-center gap-2 ${
                isSubmitting || !isValid
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-gradient-to-r from-blue-600 to-blue-700 text-white hover:from-blue-700 hover:to-blue-800"
              }`}
            >
              {isSubmitting ? (
                <>
                  <svg className="animate-spin h-5 w-5 text-white" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  <span>Envoi en cours...</span>
                </>
              ) : (
                <>
                  <span>Obtenir mon devis</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </>
              )}
            </motion.button>
          )}
        </div>
      </form>

      {/* Form Steps Indicator */}
      <div className="flex justify-center mt-8 gap-2">
        {[...Array(totalSteps)].map((_, index) => (
          <div
            key={index}
            className={`h-2 rounded-full transition-all duration-300 ${
              index + 1 <= currentStep ? "w-8 bg-blue-600" : "w-2 bg-gray-200"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default MovingForm; 