import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import HeroSection from "../components/HeroSection";

const Home = () => {
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0);

  const scrollToForm = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <HeroSection />

      {/* Floating Lead Capture */}
      <div className="fixed bottom-8 right-8 z-50">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-gradient-to-r from-blue-600 to-blue-700 text-white px-6 py-3 rounded-full shadow-lg flex items-center space-x-2 hover:shadow-xl transition-all duration-300"
          onClick={scrollToForm}
        >
          <span className="animate-pulse mr-2">💬</span>
          <span>Devis Gratuit</span>
        </motion.button>
      </div>

      {/* How It Works Section */}
      <section className="py-24 bg-white relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-transparent"></div>
        <div className="max-w-screen-xl mx-auto px-4 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-blue-600 font-semibold mb-2 block">
              DÉMÉNAGEMENT PROFESSIONNEL
            </span>
            <h2 className="text-4xl font-bold mb-6">
              <span className="bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">Votre déménagement à dans toute la Suisse romande</span>
            </h2>
            <p className="text-xl text-gray-600 max-w-xl mx-auto">
              Service de déménagement professionnel dans les cantons de Vaud et Fribourg - Une expertise locale à votre service
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-12">
            {[
              {
                step: 1,
                icon: "📝",
                title: "Devis gratuit en ligne",
                description:
                  "Demandez votre devis gratuit en ligne pour votre déménagement à Moudon, Vaud, Fribourg ou partout en Suisse",
              },
              {
                step: 2,
                icon: "🏠",
                title: "Évaluation sur place",
                description:
                  "Notre équipe se déplace dans tout le canton de Vaud et Fribourg pour évaluer précisément vos besoins",
              },
              {
                step: 3,
                icon: "🚚",
                title: "Déménagement professionnel",
                description:
                  "Service de déménagement complet avec une équipe expérimentée de Suisse romande",
              },
            ].map((item) => (
              <motion.div
                key={item.step}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: item.step * 0.2 }}
                viewport={{ once: true }}
                className="relative group"
              >
                <div className="bg-white rounded-2xl p-8 h-full shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100">
                  <div className="absolute -top-6 left-1/2 transform -translate-x-1/2">
                    <div className="bg-gradient-to-r from-blue-600 to-blue-700 w-14 h-14 rounded-full flex items-center justify-center text-white font-bold text-xl shadow-lg group-hover:scale-110 transition-transform duration-300">
                      {item.icon}
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold mb-4 mt-8">
                    {item.title}
                  </h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-24 bg-gray-50">
        <div className="max-w-screen-xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-blue-600 font-semibold mb-2 block">
              SERVICES DE DÉMÉNAGEMENT
            </span>
            <h2 className="text-4xl font-bold mb-6">
              <span className="bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">Solutions de déménagement à Vaud et Fribourg</span>
            </h2>
            <p className="text-xl text-gray-600 max-w-xl mx-auto">
              Services professionnels de déménagement adaptés à vos besoins dans les cantons de Vaud et Fribourg
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: "🏠",
                title: "Déménagement Résidentiel Vaud & Fribourg",
                description:
                  "Service complet de déménagement résidentiel dans toute la Suisse romande, spécialisé dans les régions de Vaud et Fribourg",
                features: [
                  "Emballage professionnel",
                  "Transport sécurisé local et régional",
                  "Installation à domicile",
                ],
              },
              {
                icon: "🏢",
                title: "Déménagement Entreprise Suisse Romande",
                description:
                  "Solutions de déménagement commercial efficaces pour les entreprises de Vaud et Fribourg",
                features: [
                  "Planification logistique détaillée",
                  "Équipement spécialisé professionnel",
                  "Service rapide et efficace",
                ],
              },
              {
                icon: "📦",
                title: "Services d'Emballage Vaud & Fribourg",
                description:
                  "Service d'emballage professionnel disponible dans les cantons de Vaud et Fribourg",
                features: [
                  "Matériaux d'emballage de qualité",
                  "Organisation et étiquetage",
                  "Déballage et installation",
                ],
              },
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                viewport={{ once: true }}
                className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 group cursor-pointer"
                onClick={scrollToForm}
              >
                <div className="text-4xl mb-6 duration-300">
                  {service.icon}
                </div>
                <h3 className="text-xl font-semibold mb-4 max-w-[250px]">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <span className="text-blue-500 mr-2">✓</span>
                      {feature}
                    </li>
                  ))}
                </ul>
                <div className="mt-6 text-blue-600 group-hover:text-blue-700 font-semibold flex items-center">
                  En savoir plus
                  <svg
                    className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-32 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 text-white relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-t from-blue-900/50 to-transparent"></div>
        </div>
        <div className="max-w-screen-xl mx-auto px-4 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-blue-200 font-semibold mb-2 block">
              NOS CHIFFRES CLÉS
            </span>
            <h2 className="text-4xl font-bold mb-6">
              <span className="bg-gradient-to-r from-white via-blue-100 to-white bg-clip-text text-transparent">Notre Impact en Chiffres</span>
            </h2>
          </motion.div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12">
            {[
              {
                number: "10+",
                label: "Années d'expérience",
                icon: "🏆",
                description: "D'expertise dans le déménagement",
              },
              {
                number: "4000+",
                label: "Déménagements réussis",
                icon: "🚚",
                description: "Déménagements réussis dans toute la Suisse",
              },
              {
                number: "200+",
                label: "Avis 5 étoiles",
                icon: "👥",
                description: "Clients satisfaits",
              },
              {
                number: "98%",
                label: "Clients satisfaits",
                icon: "⭐",
                description: "Recommandent nos services",
              },
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                viewport={{ once: true }}
                className="relative group"
              >
                <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 h-full hover:bg-white/20 transition-all duration-300 border border-white/10 hover:border-white/20">
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <div className="bg-gradient-to-br from-blue-400 to-blue-600 w-12 h-12 rounded-xl flex items-center justify-center text-2xl shadow-lg group-hover:scale-110 transition-transform duration-300 rotate-3">
                      {stat.icon}
                    </div>
                  </div>
                  <div className="mt-8 text-center">
                    <div className="text-5xl font-bold mb-2 bg-gradient-to-r from-blue-100 via-white to-blue-100 bg-clip-text text-transparent group-hover:scale-110 transition-transform duration-300">
                      {stat.number}
                    </div>
                    <div className="text-lg font-semibold text-blue-100 mb-2">
                      {stat.label}
                    </div>
                    <div className="text-sm text-blue-200/80">
                      {stat.description}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-32 bg-white relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-50/50 to-white/0"></div>
        <div className="max-w-screen-xl mx-auto px-4 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-blue-600 font-semibold mb-2 block">
              TÉMOIGNAGES
            </span>
            <h2 className="text-4xl font-bold mb-6">
              <span className="bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">Ce que disent nos clients</span>
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Découvrez les expériences de nos clients satisfaits qui ont fait
              confiance à notre expertise
            </p>
          </motion.div>

          <div className="relative">
            <div className="flex overflow-hidden">
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeTestimonialIndex}
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.5 }}
                  className="w-full"
                >
                  <div className="bg-gradient-to-br from-white to-blue-50 rounded-3xl p-8 md:p-12 border border-blue-100/20">
                    <div className="flex flex-col md:flex-row items-center md:items-start gap-8 mb-8">
                      <div className="relative">
                        <div className="w-24 h-24 rounded-2xl bg-gradient-to-br from-blue-100 to-blue-200 flex items-center justify-center text-4xl transform rotate-3 shadow-lg">
                          {["👨", "👩", "👨"][activeTestimonialIndex]}
                        </div>
                        <div className="absolute -bottom-3 -right-3 bg-blue-600 text-white text-sm px-3 py-1 rounded-full shadow-md">
                          Client vérifié
                        </div>
                      </div>
                      <div className="text-center md:text-left">
                        <h4 className="text-2xl font-bold text-gray-900 mb-2">
                          {
                            ["Marie L.", "Pierre D.", "Sophie M."][
                              activeTestimonialIndex
                            ]
                          }
                        </h4>
                        <div className="flex items-center justify-center md:justify-start gap-2 mb-3">
                          <div className="text-yellow-400 flex">
                            {"★★★★★".split("").map((star, i) => (
                              <span key={i} className="text-2xl">
                                {star}
                              </span>
                            ))}
                          </div>
                          <span className="text-gray-600 text-sm">
                            (Déménagement{" "}
                            {
                              ["résidentiel", "commercial", "international"][
                                activeTestimonialIndex
                              ]
                            }
                            )
                          </span>
                        </div>
                        <div className="flex items-center justify-center md:justify-start gap-2 text-sm text-gray-600">
                          <svg
                            className="w-4 h-4 text-green-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          Déménagement effectué le{" "}
                          {
                            ["15 mars 2023", "22 avril 2023", "10 mai 2023"][
                              activeTestimonialIndex
                            ]
                          }
                        </div>
                      </div>
                    </div>
                    <div className="relative">
                      <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-3 h-8 w-8 text-blue-200 opacity-50"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="text-gray-600 text-lg md:text-xl leading-relaxed pl-6">
                        {
                          [
                            "Service exceptionnel ! L'équipe était professionnelle et a pris grand soin de nos affaires. Je recommande vivement leurs services à tous ceux qui cherchent un déménagement sans stress. La communication était claire et l'équipe a dépassé nos attentes.",
                            "Déménagement sans stress et équipe très efficace. La communication était excellente du début à la fin. Un grand merci à toute l'équipe pour leur professionnalisme et leur attention aux détails !",
                            "Un service client remarquable et des déménageurs expérimentés. Ils ont dépassé toutes mes attentes. Je suis très satisfaite du résultat et je n'hésiterai pas à faire appel à leurs services à nouveau !",
                          ][activeTestimonialIndex]
                        }
                      </p>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>

            {/* Navigation Arrows */}
            <div className="absolute top-1/2 -translate-y-1/2 left-0 right-0 flex justify-between items-center pointer-events-none">
              <button
                onClick={() =>
                  setActiveTestimonialIndex((prev) =>
                    prev === 0 ? 2 : prev - 1
                  )
                }
                className="pointer-events-auto transform -translate-x-4 md:translate-x-0 bg-white/80 backdrop-blur-sm text-blue-600 p-3 rounded-full shadow-lg hover:scale-110 transition-all duration-300"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                onClick={() =>
                  setActiveTestimonialIndex((prev) =>
                    prev === 2 ? 0 : prev + 1
                  )
                }
                className="pointer-events-auto transform translate-x-4 md:translate-x-0 bg-white/80 backdrop-blur-sm text-blue-600 p-3 rounded-full shadow-lg hover:scale-110 transition-all duration-300"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>

            {/* Dots Navigation */}
            <div className="flex justify-center mt-8 space-x-3">
              {[0, 1, 2].map((index) => (
                <button
                  key={index}
                  onClick={() => setActiveTestimonialIndex(index)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    activeTestimonialIndex === index
                      ? "bg-blue-600 w-6"
                      : "bg-gray-300 hover:bg-gray-400"
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-24 bg-gray-50">
        <div className="max-w-screen-xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-blue-600 font-semibold mb-2 block">FAQ</span>
            <h2 className="text-4xl font-bold mb-6">
              <span className="bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">Questions fréquentes</span>
            </h2>
          </motion.div>

          <div className="max-w-2xl mx-auto space-y-6">
            {[
              {
                question: "Combien coûte un déménagement ?",
                answer:
                  "Les coûts varient en fonction de la distance, du volume et des services requis. Nous offrons des devis personnalisés gratuits pour vous donner un prix précis.",
              },
              {
                question: "Êtes-vous assurés ?",
                answer:
                  "Oui, nous sommes entièrement assurés et agréés pour opérer dans toute la Suisse. Votre tranquillité d'esprit est notre priorité.",
              },
              {
                question: "Combien de temps à l'avance dois-je réserver ?",
                answer:
                  "Pour garantir la disponibilité à votre date préférée, nous recommandons de réserver 2-3 semaines à l'avance, surtout pendant la haute saison.",
              },
            ].map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                viewport={{ once: true }}
                className="bg-white rounded-2xl p-8 shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <span className="text-blue-600 mr-3">Q.</span>
                  {faq.question}
                </h3>
                <p className="text-gray-600 pl-7">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="py-32 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 text-white relative overflow-hidden">
        {/* Animated background elements */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 opacity-10">
            <div className="absolute inset-0" style={{
              backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255,255,255,0.15) 1px, transparent 0)`,
              backgroundSize: '40px 40px'
            }}></div>
          </div>
          <div className="absolute inset-0 bg-gradient-to-t from-blue-900/50 to-transparent"></div>
          <div className="absolute inset-0">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute rounded-full mix-blend-overlay opacity-20"
                style={{
                  width: `${Math.random() * 400 + 200}px`,
                  height: `${Math.random() * 400 + 200}px`,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  background: 'radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%)',
                  animation: `pulse ${Math.random() * 4 + 4}s infinite`,
                  animationDelay: `${Math.random() * 4}s`
                }}
              ></div>
            ))}
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto px-4 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-md rounded-3xl p-12 md:p-16 border border-white/10 shadow-2xl"
          >
            <div className="relative">
              {/* Decorative elements */}
              <div className="absolute -top-12 -left-12 w-24 h-24 bg-gradient-to-br from-blue-400 to-blue-600 rounded-full opacity-20 blur-2xl"></div>
              <div className="absolute -bottom-12 -right-12 w-32 h-32 bg-gradient-to-br from-blue-300 to-blue-500 rounded-full opacity-20 blur-2xl"></div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                viewport={{ once: true }}
                className="text-center relative z-10"
              >
                <span className="text-blue-200 font-semibold mb-4 block text-sm uppercase tracking-wider">
                  Commencez Maintenant
                </span>
                <h2 className="text-4xl md:text-5xl font-bold mb-6">
                  <span className="bg-gradient-to-r from-white via-blue-100 to-white bg-clip-text text-transparent">Prêt à déménager sereinement ?</span>
                </h2>
                <p className="text-xl mb-10 text-blue-100/90 max-w-2xl mx-auto leading-relaxed">
                  Obtenez votre devis personnalisé gratuit en quelques clics et découvrez comment nous pouvons rendre votre déménagement simple et sans stress
                </p>

                <div className="flex flex-col md:flex-row items-center justify-center gap-6 mt-12">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={scrollToForm}
                    className="bg-gradient-to-r from-white to-blue-50 text-blue-900 px-8 py-4 rounded-xl font-semibold text-lg shadow-xl hover:shadow-2xl transition-all duration-300 flex items-center group relative overflow-hidden"
                  >
                    <span className="relative z-10 leading-snug">Demander un devis gratuit</span>
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                    <svg
                      className="w-5 h-5 ml-2 relative z-10 transform group-hover:translate-x-1 transition-transform duration-300"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </motion.button>

                  <div className="flex items-center gap-8 text-blue-100/80">
                    <div className="flex items-center gap-2">
                      <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span className="leading-snug">Devis gratuit</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <svg className="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span className="leading-snug">Sans engagement</span>
                    </div>
                  </div>
                </div>

                {/* Trust indicators */}
                <div className="mt-12 pt-12 border-t border-white/10">
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                    {[
                      { icon: "🏆", text: "Service 5 étoiles" },
                      { icon: "🔒", text: "100% Sécurisé" },
                      { icon: "⚡", text: "Réponse rapide" },
                      { icon: "💯", text: "Satisfaction garantie" }
                    ].map((item, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4, delay: index * 0.1 }}
                        viewport={{ once: true }}
                        className="flex flex-col items-center gap-2 group"
                      >
                        <div className="text-2xl mb-1 transform group-hover:scale-110 transition-transform duration-300">{item.icon}</div>
                        <div className="text-sm text-blue-100/80 group-hover:text-white transition-colors duration-300">{item.text}</div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Home;
